import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { EventState } from 'helpers/types/jwplayer-subgraph';
import { getSeriesDuration } from 'helpers/utils/media/getSeriesDuration';
import { format } from 'date-fns';
import { getSeriesPreviewDetails } from 'helpers/utils/media/getSeriesPreviewDetails';
import { getFilmPreviewDetails } from 'helpers/utils/media/getFilmPreviewDetails';
import { createURL } from 'helpers/utils/url/createURL';
import { routes } from 'lib/routes';
import { getEpisodePreviewDetails } from 'helpers/utils/media/getEpisodePreviewDetails';
import { getMediaTitle } from 'helpers/utils/media/getMediaTitle';
import { videoUrlSlug } from 'helpers/utils/slugify';

const mapEventsIntoProps = (mediaItem: MediaItem) => {
  const {
    description,
    liveState,
    mediaId,
    querystring,
    scheduledStart,
    title
  } = mediaItem;

  const defaultHref = routes.liveEvents();
  const liveEventPathname = routes.temporaryEventDetail(mediaId);
  const liveEventHref = querystring
    ? createURL(liveEventPathname, querystring)
    : liveEventPathname;

  const actionButtonHref = [EventState.Live, EventState.Vod].includes(liveState)
    ? liveEventHref
    : defaultHref;

  return {
    actionButtonHref,
    additionalLabels: [
      format(new Date(scheduledStart), 'MMM d, yyyy '),
      format(new Date(scheduledStart), ' hh:mm a')
    ],
    altHeading: null,
    analyticsName: 'Events detail page link',
    category: null,
    ctaTextButton: null,
    eyebrow: null,
    heading: title,
    hoverTileHeading: null,
    href: defaultHref,
    isIncludedWithOPlus: null,
    liveState,
    mediaProgress: null,
    subHeadingText: format(new Date(scheduledStart), 'MMMM d, yyyy - hh:mm a'),
    tags: null,
    textBlock: description
  };
};

const mapSeriesIntoProps = (series: MediaItem) => {
  const { productionYear, seriesCount } = series;

  return {
    ...getSeriesPreviewDetails(series),
    additionalLabels: [getSeriesDuration(seriesCount), productionYear],
    analyticsName: 'Series detail page link',
    liveState: null,
    subHeadingText: getSeriesDuration(seriesCount) || 'Series'
  };
};

const mapEpisodeToProps = (episode: MediaItem) => {
  const { category } = episode;

  return {
    ...getEpisodePreviewDetails(episode),
    analyticsName: 'Episodes detail page link',
    category,
    liveState: null,
    subHeadingText: category
  };
};

const mapFilmIntoProps = (film: MediaItem, allowTrailers: boolean) => {
  const { description, tags } = film;

  const isTrailer = film.title.includes('| Trailer');

  return {
    ...getFilmPreviewDetails(film),
    altHeading: null,
    analyticsName: 'Films detail page link',
    hoverTileHeading: null,
    liveState: null,
    tags,
    textBlock: description,
    // temp-trailers - temporary solution to treat trailers as shorts in some carousels
    ...(allowTrailers &&
      isTrailer && {
        actionButtonHref: null,
        heading: film.title,
        href: routes.shortsDetail(
          videoUrlSlug(getMediaTitle(film.title)),
          film.mediaId
        )
      })
  };
};

export const getCarouselProps = (slide: MediaItem, allowTrailers?: boolean) => {
  const {
    associatedTrailerId,
    images,
    isFreeContent,
    location,
    mediaId,
    poster
  } = slide;

  const videoId = associatedTrailerId || mediaId;

  const commonProps = {
    creator: null,
    duration: null,
    image: images[1].src,
    isFreeContent,
    location,
    mediaId,
    poster,
    videoId
  };

  const isSeries = slide.seriesId;
  const isEvent = slide.liveState;
  const isEpisode = slide.contentType === 'episode';

  if (isEpisode) {
    return {
      ...commonProps,
      ...mapEpisodeToProps(slide)
    };
  }

  if (isSeries) {
    return {
      ...commonProps,
      ...mapSeriesIntoProps(slide)
    };
  }

  if (isEvent) {
    return {
      ...commonProps,
      ...mapEventsIntoProps(slide)
    };
  }

  return {
    ...commonProps,
    ...mapFilmIntoProps(slide, allowTrailers)
  };
};

export type MediaCarouselMapper = typeof getCarouselProps;
